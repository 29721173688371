.project-container {
  @apply flex justify-center gap-4 md:gap-8 pb-20
}

.project-card {
  @apply w-64 h-xl border shadow-md rounded tracking-wider
}

.project-image{
  @apply rounded-full mt-4 p-2
}

.project-link{
  @apply no-underline text-purple-800	
}

.nav{
  @apply flex justify-between items-center h-16 bg-white text-black relative shadow-sm tracking-widest 
}

.nav-link{
  @apply p-4 hover:bg-purple-500 hover:text-white transition ease-linear duration-500 rounded cursor-pointer focus:bg-purple-500
}

.logo{
  @apply pl-8 font-bold md:font-bold tracking-tighter cursor-pointer focus:bg-black
}

.text{
  @apply text-black font-light text-lg leading-normal tracking-wider
}   